<mcdo-booking-form 
    [bookingData]="booking"
    [stores]="stores"
    [storeTooltipExtendedTemplate]="storeTooltipTemplate"
    [firstStepLabelTemplate]="secondStepLabel"
    [eventInfoStore]="eventInfoStore"
    [eventRecapTemplate]="recapTemplate"
    [legalConditions]="legalConditions"
    confirmationImage="/assets/images/birthday_planner_child.webp">
    <app-birthday-party-booking (onSubmit)="booking = $event" [themes]="themes"></app-birthday-party-booking>
</mcdo-booking-form>

<ng-template #secondStepLabel> 
    <i class="bi bi-cake2-fill h1 text-primary"></i>
</ng-template>

<ng-template #storeTooltipTemplate let-params>
    @if(params.slots) {
        <div>
            <i class="bi bi-person-fill"></i>
            {{ 'booking-form.store-slot-select.numChilds' | translate }} : {{ params.slots.maxGuest }}
        </div>
        <div>
            <i class="bi bi-cake2-fill"></i>
            {{ 'booking-form.store-slot-select.ageChild' | translate }} : {{ params.slots.minAge }} - {{ params.slots.maxAge }}
        </div>
    } @else {
        {{ "booking-form.store-slot-select.noSlotsAvailable" | translate }}
    }
</ng-template>

<ng-template #eventInfoStore let-params>
    <div class="mb-3">
        <label class="form-label">{{ "booking-form.store-slot-select.price" | translate }}</label>
        <div class="text-danger">CHF {{ calculatePrice(params.slots.includedGuests, params.slots.price, params.slots.guestPrice) }}</div>
    </div>
</ng-template>

<ng-template #recapTemplate let-event>
    <app-birthday-party-recap [booking]="booking"></app-birthday-party-recap>
</ng-template>