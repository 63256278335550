import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { IntegerInputDirective } from 'common';
import { BirthdayPartyBooking } from '../../../data/birthday-party-booking';
import { BirthdayTheme } from '../../../data/birthday-theme';
import { ThemeSelectorComponent } from "./theme-selector/theme-selector.component";

@Component({
  selector: 'app-birthday-party-booking',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, ThemeSelectorComponent, NgbTooltipModule, IntegerInputDirective],
  templateUrl: './birthday-party-booking.component.html',
  styleUrl: './birthday-party-booking.component.scss'
})
export class BirthdayPartyBookingComponent implements OnInit {

  isAuthenticated: boolean;

  @Input({required: true})
  themes: BirthdayTheme[]

  @Output()
  onSubmit = new EventEmitter<BirthdayPartyBooking>();

  birthdayPartyForm = this.fb.group({
    name: this.fb.control<string>(null, { validators: Validators.required }),
    age: this.fb.control<number>(null, { validators: Validators.required }),
    gender: this.fb.control<string>(null, { validators: Validators.required }),
    theme: this.fb.control<BirthdayTheme>(null, { validators: Validators.required }),
    childrenCount: this.fb.control<number>(null, { validators: Validators.required }),
    language: this.fb.control<string>(null, { validators: Validators.required }),
    comment: this.fb.control<string>(null)
  });

  constructor(
    private fb: FormBuilder,
    private securityService: OidcSecurityService
  ) { }

  ngOnInit(): void {
    this.securityService.isAuthenticated().subscribe(isAuthenticated => this.isAuthenticated = isAuthenticated);
  }

  getPartyPrice() {
    const themeConfig = this.birthdayPartyForm.value.theme;
    let extraChildPricing = 0;
    if (this.birthdayPartyForm.controls.childrenCount.valid) {
      let extraChildrenCount = this.birthdayPartyForm.controls.childrenCount.value - themeConfig.includedGuestCount;

      if (extraChildrenCount > 0) {
        extraChildPricing = extraChildrenCount * themeConfig.guestPrice;
      }
    }

    return themeConfig.basePrice + extraChildPricing;
  }

  onFormSubmit() {
    if (this.birthdayPartyForm.valid) {
      const val = this.birthdayPartyForm.value;
      
      const birthdayPartyEvent = new BirthdayPartyBooking();
      birthdayPartyEvent.childName = val.name;
      birthdayPartyEvent.childAge = val.age;
      birthdayPartyEvent.childGender = val.gender;
      birthdayPartyEvent.themeId = val.theme.id;
      birthdayPartyEvent.guestCount = val.childrenCount;
      birthdayPartyEvent.language = val.language;
      birthdayPartyEvent.comment = val.comment;
      this.onSubmit.emit(birthdayPartyEvent);
    }
  }

}
