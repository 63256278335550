import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ThemeService } from '../../../../../services/themes-service';
import { BirthdayTheme } from '../../../../data/birthday-theme';

@Component({
  selector: 'app-theme-selector',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './theme-selector.component.html',
  styleUrl: './theme-selector.component.scss',
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => ThemeSelectorComponent) }
  ]
})
export class ThemeSelectorComponent implements ControlValueAccessor {

  _childAge: number;

  @Input()
  set childAge(val : number) {
    this._childAge = val;
    if (this._currentTheme?.minAge > val) {
      this.currentTheme = null;
    }
  }

  get childAge() {
    return this._childAge;
  }

  private _currentTheme: BirthdayTheme;

  get currentTheme() {
    return this._currentTheme;
  }

  set currentTheme(theme: BirthdayTheme) {
    this._currentTheme = theme;
    this.onTouched();
    this.onChange(theme);
  }

  @Input({required: true})
  set themes(themes: BirthdayTheme[]) {
    const legacyThemes = ["circus", "fairy", "superhero"];
    const availableThemes = themes.filter(y => y.availableUntil == null || new Date(y.availableUntil) > new Date())
    this.legacyThemes = availableThemes.filter(y => legacyThemes.includes(y.name));
    this.availableThemes = availableThemes.filter(y => !legacyThemes.includes(y.name))
  }

  legacyThemes: BirthdayTheme[];
  availableThemes: BirthdayTheme[];

  constructor(public themeService: ThemeService) 
  {}

  private onChange = (_: BirthdayTheme) => {};
  private onTouched = () => {};

  writeValue(theme: BirthdayTheme): void {
    this.currentTheme = theme;
  }

  registerOnChange(fn: (theme: BirthdayTheme) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    
  }

  setTheme(theme: BirthdayTheme) {
    if (this.childAge >= theme.minAge && this.childAge <= theme.maxAge) {
      this.currentTheme = theme;
    }
  }
}
