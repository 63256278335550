<p>Dès le 3 mars 2025, découvre nos fêtes d’anniversaire complètement revisitées avec pour thème "Be the Birthday Boss" !</p>
<div class="theme-container">
    @for (theme of availableThemes; track $index) {
        <div class="theme" (click)="setTheme(theme)">
            <div>
                <div class="theme-overlay" [class.selected-overlay]="currentTheme === theme">
                    <i class="bi bi-check-circle" aria-hidden="true"></i>
                </div>
                <img [src]="'assets/themes/' + theme.name + '.jpg'" [className]="theme.minAge > childAge ? 'disabled' : ''" />
            </div>
            <div
                style="text-align: center;"
                [style.font-weight]="currentTheme === theme ? 'bold' : 'unset'"
            >
                {{ 'global.theme.' + theme.name| translate }}
            </div>
        </div>
    }
</div>

<p class="pt-2">Jusqu’au 2 mars, tu pourras encore profiter de nos super thèmes : Super-héros, Contes de fées ou Cirque</p>
<div class="theme-container">
    @for (theme of legacyThemes; track $index) {
        <div class="theme" (click)="setTheme(theme)">
            <div>
                <div class="theme-overlay" [class.selected-overlay]="currentTheme === theme">
                    <i class="bi bi-check-circle" aria-hidden="true"></i>
                </div>
                <img [src]="'assets/themes/' + theme.name + '.jpg'" [className]="theme.minAge > childAge ? 'disabled' : ''" />
            </div>
            <div
                style="text-align: center;"
                [style.font-weight]="currentTheme === theme ? 'bold' : 'unset'"
            >
                {{ 'global.theme.' + theme.name| translate }}
            </div>
        </div>
    }
</div>