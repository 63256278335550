<div class="row">
    <form class="col-md-8 mx-auto" [formGroup]="birthdayPartyForm" (ngSubmit)="onFormSubmit()">
        <div class="mb-3">
            <label for="nameInput" class="form-label">{{ "booking-form.birthday-party.childName" | translate }}</label>
            <input id="nameInput" class="form-control" type="text" formControlName="name">
        </div>
    
        <div class="mb-3">
            <label for="ageInput" class="form-label">{{ "booking-form.birthday-party.childAge" | translate }}</label>
            <input id="ageInput" class="form-control" type="number" formControlName="age" [min]="4" [max]="12">
            @if(birthdayPartyForm.controls.age.touched) {
                @if(birthdayPartyForm.controls.age.hasError('max')) {
                    <div class="text-danger">
                        {{ 'booking-form.birthday-party.error.maxAge' | translate: { max: 12 } }}
                    </div>
                }
        
                @if(birthdayPartyForm.controls.age.hasError('min')) {
                    <div class="text-danger">
                        {{ 'booking-form.birthday-party.error.minAge' | translate: { min: 4 } }}
                    </div>
                }
        
                @if(birthdayPartyForm.controls.age.value < 5) {
                    <div class="text-warning">
                        <i class="bi bi-exclamation-triangle"></i> {{ "booking-form.birthday-party.warning.adultNeeded" | translate }}
                    </div>
                }
            }
        </div>
    
        <div class="mb-3">
            <div>
                <input type="radio" class="btn-check" id="genderBoyRadio" formControlName="gender" value="boy">
                <label class="btn btn-outline-primary me-3" for="genderBoyRadio">{{ "global.childGender.boy" | translate }}</label>
                <input type="radio" class="btn-check" id="genderGirlRadio" formControlName="gender" value="girl">
                <label class="btn btn-outline-primary me-3" for="genderGirlRadio">{{ "global.childGender.girl" | translate }}</label>
                <input type="radio" class="btn-check" id="noGenderRadio" formControlName="gender" value="noanswer">
                <label class="btn btn-outline-primary" for="noGenderRadio">{{ "global.childGender.none" | translate }}</label>
            </div>
        </div>
    
        <div class="mb-3">
            <label for="themeSelect" class="form-label">{{ "booking-form.birthday-party.themeChoice" | translate }}</label>
            <app-theme-selector id="themeSelect" formControlName="theme" [themes]="themes" [childAge]="birthdayPartyForm.value.age"></app-theme-selector>
        </div>
    
        @if (birthdayPartyForm.value.theme != null) {
            <div class="mb-3">
                <label for="" class="form-label">{{ "booking-form.birthday-party.childCount" | translate }}</label>
                <input type="number" class="form-control" formControlName="childrenCount" [max]="birthdayPartyForm.value.theme.maxGuest" min="1" integerInput>
        
                @if(birthdayPartyForm.controls.childrenCount.touched) {
                    @if(birthdayPartyForm.controls.childrenCount.hasError('max')) {
                        <div class="text-danger">
                            {{ 'booking-form.birthday-party.error.maxKids' | translate: { max: birthdayPartyForm.value.theme.maxGuest } }}
                        </div>
                    }
            
                    @if(birthdayPartyForm.controls.childrenCount.hasError('min')) {
                        <div class="text-danger">
                            {{ 'booking-form.birthday-party.error.minKids' | translate }}
                        </div>
                    }
                }
            </div>
        
            <div class="mb-3 text-end text-danger fw-bold">
                <i class="bi bi-question-circle" [ngbTooltip]="priceTooltip"></i> {{"booking-form.birthday-party.price" | translate}} CHF {{ getPartyPrice() }} *
            </div>
        
            <div class="mb-3">
                <label for="languages" class="form-label">{{ "booking-form.birthday-party.party-language" | translate }}</label>
                <div id="languages">
                    @for (language of ["FR", "DE", "IT"]; track $index) {
                        <input type="radio" class="btn-check" [id]="language" formControlName="language" [value]="language">
                        <label class="btn btn-outline-primary me-3" [htmlFor]="language">{{ "global.language." + language | translate }}</label>
                    }
                </div>
            </div>
        
            @if (isAuthenticated) {
                <div class="mb-3">
                    <label for="comment" class="form-label">{{ "booking-form.birthday-party.comment" | translate }}</label>
                    <textarea id="comment" class="form-control" maxlength="500" formControlName="comment"></textarea>
                </div>
            }
        
            <div class="mb-3">
                <button class="btn btn-secondary" type="submit">{{ "global.button.validate" | translate }}</button>
            </div>
        
            <p>* {{ "booking-form.birthday-party.price-disclaimer" | translate: { includedChildCount: birthdayPartyForm.value.theme?.includedGuestCount} }}</p>
        }
    </form>
    
    <ng-template #priceTooltip>
        <div>
            {{ "booking-form.birthday-party.price-base" | translate: { includedChildCount: birthdayPartyForm.value.theme?.includedGuestCount } }}: CHF {{ birthdayPartyForm.value.theme?.basePrice }}
        </div>
        <div>
            CHF {{ birthdayPartyForm.value.theme?.guestPrice }} {{ "booking-form.birthday-party.price-extraChild" | translate }}
        </div>
    </ng-template>
</div>