import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { faBarChart, faCalendar, faCalendarPlus, faCheckSquare, faCogs, faListAlt, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { NavbarComponent, navbarMenu } from 'common';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NavbarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Birthday Planner';
  customMenus: navbarMenu[] = [
    {
      label: "global.menu.booking.main",
      icon: faCheckSquare,
      authorizedRoles: ['ADMIN', 'TELEPERFORMANCE_ADMIN', 'RESTAURANT_MANAGER', 'FAMILY_MARKET', 'RESTAURANT_HOST', 'TELEPERFORMANCE_OP'],
      menuItems: [
        {
          label: "global.menu.booking.booking",
          icon: faCalendarPlus,
          link: ""
        },
        {
          label: "global.menu.booking.calendar",
          icon: faCalendar,
          link: "calendar"
        },
        {
          label: "global.menu.booking.list",
          icon: faListAlt,
          link: "list"
        }
      ]
    },
    {
      label: "global.menu.admin.main",
      icon: faUserPlus,
      authorizedRoles: ['ADMIN', 'TELEPERFORMANCE_ADMIN', 'RESTAURANT_MANAGER', 'FAMILY_MARKET', 'RESTAURANT_HOST', 'TELEPERFORMANCE_OP'],
      menuItems: [
        {
          label: "global.menu.admin.userManagement",
          icon: faUser,
          link: environment.authority + "/users",
          authorizedRoles: ['ADMIN', 'TELEPERFORMANCE_ADMIN', 'RESTAURANT_MANAGER', 'FAMILY_MARKET']
        },
        {
          label: "global.menu.admin.dashboard",
          icon: faBarChart,
          link: "dashboard",
          authorizedRoles: ['ADMIN', 'TELEPERFORMANCE_ADMIN', 'RESTAURANT_MANAGER', 'FAMILY_MARKET']
        },
        {
          label: "global.menu.admin.settings",
          icon: faCogs,
          link: "settings",
          authorizedRoles: ['ADMIN', 'TELEPERFORMANCE_ADMIN', 'RESTAURANT_MANAGER', 'FAMILY_MARKET', 'RESTAURANT_HOST']
        }
      ]
    }
  ]
}
